

export const countries = [
  { code: 'DK', name: 'Denmark', emoji: '🇩🇰' },
  { code: 'FI', name: 'Finland', emoji: '🇫🇮' },
  { code: 'NO', name: 'Norway', emoji: '🇳🇴' },
  { code: 'SE', name: 'Sweden', emoji: '🇸🇪' },
  { code: 'US', name: 'United States', emoji: '🇺🇸' },
];

export const countriesByCode = countries.reduce((acc, country) => {
  acc[country.code] = country;
  return acc;
}, {} as Record<string, typeof countries[number]>);
