import { FC } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";


export const SearchField: FC<TextFieldProps> = function SearchField({ sx={}, ...rest }) {
  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton sx={{ px: 0 }}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: 'grey.200'
        },
        '& .MuiOutlinedInput-root:hover': {
          backgroundColor: 'grey.200'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
        ...sx
      }}
      {...rest}
    />
  );
}
