import { FC, useState } from 'react';

import { CanonicalContent, Movie, Rating, Share, TvShow, Unfurl } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { useSnackbar } from '@/hooks/snackbar';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';
import { CompactUnfurl, CompactUnfurlProps } from '@/components/unfurl/CompactUnfurl';
import { LinkBox } from '@/components/LinkBox';
import { canonicalLink } from '@/utils/routes';


export type SmallItemProps = {
  share?: Pick<Share, '_id'> & {
    message?: Pick<Share['message'], 'link'> | null,
    rating?: Pick<Rating, 'rating'> | null,
    unfurl: Pick<Unfurl, 'title' | 'link' | 'images'>,
  } & CompactUnfurlProps['share'] | null,
  canonicalContent?: Pick<CanonicalContent, '_id' | 'type'> & {
    rating?: Pick<Rating, 'rating'> | null,
    movie?: Pick<Movie, 'title' | 'poster'> | null,
    tvshow?: Pick<TvShow, 'title' | 'poster'> | null,
  } & DialogProps['canonicalContent'] | null,
}


export const SmallItem: FC<SmallItemProps> = function SmallItem({ share, canonicalContent }) {

  const isWatched = useIsWatched(share?._id, canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (active: boolean) => toggleWatched(canonicalContent?._id, share?._id, active);
  const isInWatchlist = useIsInWatchlist(share?._id, canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (active: boolean) => toggleInWatchlist(canonicalContent?._id, share?._id, active);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  return (
    <>
      <SwipeableUnfurl
        isWatched={isWatched}
        setIsWatched={(v) => {
          setIsWatched(v);
          v && watchedSnackbar();
        }}
        isInWatchlist={isInWatchlist}
        setIsInWatchlist={(v) => {
          setIsInWatchlist(v);
          v && watchlistSnackbar(() => setDialogOpen(true));
        }}
      >
        <LinkBox href={canonicalLink(canonicalContent?._id, share?._id)} sx={{ maxWidth: '100%' }}>
          <CompactUnfurl
            share={share || undefined}
            canonicalContent={canonicalContent}
            sx={{ border: 'none', backgroundColor: 'grey.100' }}
            setDialogOpen={setDialogOpen}
          />
        </LinkBox>
      </SwipeableUnfurl>

      {dialogOpen &&
        <Dialog share={share} canonicalContent={canonicalContent} open={dialogOpen} setOpen={setDialogOpen} />
      }
    </>
  );
}
