import { FC, useState } from 'react';
import { Box, Button, List, ListItem, ListItemText, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Mail, Send } from 'react-feather';
import * as EmailValidator from 'email-validator';

import { Group } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { useDeviceContacts } from '../../hooks/contacts';
import { useInvite } from '../../hooks/invite';
import { TextField } from '../../components/TextField';
import { Loading } from '../../components/Loading';


export interface InviteEmailProps {
  group?: Pick<Group, '_id' | 'name'>,
  onComplete: () => void,
  from: 'group' | 'friends' | 'create_group' | 'profile',
}


export const InviteEmail: FC<InviteEmailProps> = function InviteEmail({ group, onComplete, from }) {

  const theme = useTheme();
  const { user } = useSession();

  const [inviteInput, setInviteInput] = useState<string>('');

  const { filteredDeviceContacts } = useDeviceContacts(user?.contacts, inviteInput, user, undefined);

  const inputIsEmail = EmailValidator.validate(inviteInput);

  const { inviteEmail, loading } = useInvite(from, onComplete, group);

  const invite = () => {
    inviteEmail(inviteInput);
    setInviteInput('');
  }

  if(loading) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          value={inviteInput}
          color='primary'
          placeholder='Add email...'
          type="email"
          sx={{ flex: '1 1 auto' }}
          onChange={ev => setInviteInput(ev.target.value)}
          InputProps={{
            startAdornment: <Mail style={{ width: 20, height: 20, marginRight: 8, color: theme.palette.grey[300] }} />
          }}
          data-cy="inviteemail-text"
        />
        <Button color='primary' variant='outlined' sx={{ flex: '0 0 auto', ml: 2, height: '40px', padding: 1, color: theme.palette.primary.main }} disabled={!inputIsEmail} onClick={invite} data-cy="inviteemail-submit">
          <Send width={20} height={20} style={{ marginRight: '4px' }} />
          Send
        </Button>
      </Box>
      <Box sx={{ position: 'relative', width: '100%', height: 0 }}>
        {inviteInput && filteredDeviceContacts.length > 0 && !inputIsEmail &&
          <Box sx={{ px: 1, pt: 0.5, position: 'absolute', width: '100%', zIndex: 1 }}>
            <Paper elevation={2}>
              <List dense>

                {filteredDeviceContacts.slice(0, 3).map(dc => (
                  <ListItem key={dc.contactId} onClick={() => setInviteInput(dc.emails[0].address || '')}>
                    <ListItemText primary={dc.emails[0].address} secondary={dc.displayName} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Box>
        }
      </Box>
    </>
  );
}
