import { useEffect, useCallback, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { Box, Typography } from '@mui/material';

import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { firebaseAuth } from '../../firebase';
import { LoadingIceCream } from '../../components/LoadingIceCream';


export const Logout: FC<{ next: string }> = function Logout({ next = '/feed' }) {

  const history = useHistory();
  const { track } = useAnalyticsQueued()

  const logout = useCallback(() => {
    track('logout', { category: 'auth' });
    signOut(firebaseAuth)
      .then(() => history.push(next))
      .catch((err) => console.error(err));
  }, [ history, next, track ]);

  useEffect(() => {
    logout();
  }, [ logout ]);

  return (
    <>
      <Typography variant="h3" sx={{ mb: 3, textAlign: 'center' }}>
        Logging out...
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 6, width: '100%' }}>
        <LoadingIceCream style={{ width: '30px' }} />
      </Box>
    </>
  );
}
