import { FC } from 'react';
import { NoSsr } from '@mui/material';
import loadable from '@loadable/component';

import { Router } from './Container';
const WelcomeContent = loadable(() => import(/* webpackChunkName: "welcome" */'./WelcomeContent'));


export const Welcome: FC<{ router: Router }> = function Welcome({ router }) {

  return (
    <NoSsr>
      <WelcomeContent router={router} />
    </NoSsr>
  );
}
