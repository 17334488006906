export const error = (error) => {
  let userMessage = {};
  let consoleError = true;
  let doTrack = true;

  if (error?.code === 'auth/popup-closed-by-user') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  } else if (error?.code === 'auth/user-cancelled') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  } else if (error?.code === 'auth/popup-blocked') {
    userMessage = undefined;
    consoleError = false;
    doTrack = false;
  } else if (error?.message === 'The user canceled the sign-in flow.') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  // Twitter Android SDK
  } else if (error?.message?.match('The user did not grant your application the permissions it requested.') !== null) {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  // Twitter Android SDK ??
  } else if (error?.message === 'The web operation was canceled by the user.') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  // Twitter iOS SDK
  } else if (error?.message === 'The interaction was cancelled by the user.') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  // Facebook iOS SDK
  } else if (error?.message === 'Sign in canceled.') {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  // Apple iOS SDK
  } else if (error?.message?.match('The operation couldn.t be completed.') !== null) {
    userMessage = { text: 'Login cancelled', severity: 'error' };
    consoleError = false;
  } else {
    userMessage = { text: error?.message, severity: 'error' };
  }

  return [ userMessage, consoleError, doTrack ];
}
