

export function hashCode (str: string): number {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function truncate(input: string, length: number, killwords?: string[], end?: string): string {

  input = input || '';
  length = length || 255;

  if (input.length <= length) {
    return input;
  }

  if (killwords) {
    input = input.substring(0, length);
  } else {
    let idx = input.lastIndexOf(' ', length);
    if (idx === -1) {
      idx = length;
    }

    input = input.substring(0, idx);
  }

  // trim punctuation chars from end
  input = input.replace(new RegExp("[ .,_—()–-]+$"), "");

  input += (end !== undefined && end !== null) ? end : '…';
  return input;
}


export function stripTags(input: string): string {
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}
