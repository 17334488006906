import { FC, RefObject, memo } from 'react';
import { Box, Typography } from '@mui/material';

import { useGetPopularListsQuery } from '../../generated/graphql';
import { LazyRender } from '../../components/LazyRender';
import { ListCard } from '../../components/ListCard';


export type PopularListsProps = {
  scrollContainerRef: RefObject<HTMLElement>
}


export const PopularLists: FC<PopularListsProps> = memo(function PopularLists({ scrollContainerRef }: PopularListsProps) {

  const { data, loading, error } = useGetPopularListsQuery({ variables: { input: { limit: 8 } } });

  if(error) {
    console.error(error);
  }

  const lists = data?.getPopularLists;

  if(loading || !lists || lists.length < 3) {
    return null;
  }

  return (
    <LazyRender
      height={257}
      offset={1000}
      resize={true}
      unmountIfInvisible={true}
      overflow={true}
      scrollContainerRef={scrollContainerRef}
    >
      <Box sx={{ px: 0, pb: 1 }}>
        <Typography variant='h3' sx={{ px: 2, pt: 1.5, pb: 1 }}>
          Popular Collections
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, overflowX: 'scroll', px: 2 }}>
          {lists.map(list => (
            <ListCard key={list?._id} list={list} />
          ))}
        </Box>
      </Box>
    </LazyRender>
  );
});
