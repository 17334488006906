import { ChangeEvent, FC, SyntheticEvent, useCallback, useState } from 'react';
import { Typography, FormGroup, FormControlLabel, Checkbox, Link, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as EmailValidator from 'email-validator';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAdditionalUserInfo } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { Facebook } from '@mui/icons-material';

import { useTranslation } from '../../utils/i18n';
import { isMacLike } from '../../utils/detect';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSnackbar } from '@/hooks/snackbar';
import { firebaseAuth } from '../../firebase';
import { EmailInput, PasswordInput, AppleButton, GoogleButton, FacebookButton } from '../../components/auth';
import { Router } from './Container';


export const Register: FC<{ router: Router, email: string, setEmail: (email: string) => void, password: string, setPassword: (p: string) => void }> = function Register({ router, email, setEmail, password, setPassword }) {

  const { track } = useAnalyticsQueued();
  const { t } = useTranslation('auth');

  const [ loading, setLoading ] = useState<boolean>();
  const [ showMore, setShowMore ] = useState<boolean>(false);

  const { show } = useSnackbar();
  const [ acceptTerms, setAcceptTerms ] = useState<boolean>(false);

  const register = useCallback((ev: SyntheticEvent) => {
    track('register', { category: 'auth', action: 'register', provider: 'email-and-password' });
    ev.preventDefault();
    setLoading(true);
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then(() => {
        // We should have successful auth
        // User is always new
        track('login', { category: 'auth', action: 'register', provider: 'email-and-password' });
      })
      .catch(e => {
        track('register_auth_error', { category: 'auth', error: e.code, action: 'register', provider: 'email-and-password' });
        if(e.code === 'auth/email-already-in-use') {
          signInWithEmailAndPassword(firebaseAuth, email, password)
          .then((result) => {
            // We should have successful auth
            const userInfo = getAdditionalUserInfo(result);
            if (userInfo?.isNewUser) {
              track('signup', { category: 'auth', action: 'register', provider: 'email-and-password' });
            } else {
              track('login', { category: 'auth', action: 'register', provider: 'email-and-password' });
            }
          })
          .catch((e) => {
            track('login_auth_error', { category: 'auth', error: e.code, action: 'register', provider: 'email-and-password' });
            if(e.code === 'auth/wrong-password') {
              show({ text: 'Email is already taken. If it is yours, try to login.', severity: 'info' });
              setLoading(false);
            } else {
              console.error(e);
              show({ text: e.message, severity: 'error' });
              setLoading(false);
            }
          });
        } else {
          console.error(e);
          show({ text: e.message, severity: 'error' });
          setLoading(false);
        }
      });
  }, [ show, track, email, password ]);


  const handleAcceptTerms = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    track('register_accept_terms', { category: 'auth', action: 'register', provider: 'email-and-password', value: ev.target.checked });
    setAcceptTerms(ev.target.checked);
  }, [ track ]);


  const handleClickShowMore = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('register_show_more', { category: 'auth', action: 'register' });
    setShowMore(true);
  }, [ track ]);


  return (
    <>
      <GoogleButton>{t('register_with_google')}</GoogleButton>

      {(isMacLike || Capacitor.getPlatform() === 'ios') && (
        <AppleButton>{t('register_with_apple')}</AppleButton>
      )}

      {!showMore &&
        <Typography variant="body2" component="div" sx={{ textAlign: 'center', mt: 1 }}>
          <Link underline="none" sx={{ display: 'inline-flex', alignItems: 'flex-end', color: 'text.primary' }} onClick={handleClickShowMore}>
            {t('register_with')}&nbsp;
            {/* <Twitter fontSize='medium' /> */}
            <Facebook fontSize='medium' />
          </Link>
        </Typography>
      }

      {showMore &&
        <>
          {/* <TwitterButton>{t('register_with_twitter')}</TwitterButton> */}
          <FacebookButton>{t('register_with_facebook')}</FacebookButton>
        </>
      }

      <Divider sx={{ mt: 2, mb: 3 }}>{t('or')}</Divider>

      <form onSubmit={register}>

        <EmailInput email={email} setEmail={setEmail} />

        <PasswordInput password={password} setPassword={setPassword} placeholder={t('choose_password')} register />

        <FormGroup sx={{ m: 0, mb: 2 }}>
          <FormControlLabel sx={{ m: 0 }} control={
            <Checkbox
              color="primary"
              checked={acceptTerms}
              onChange={handleAcceptTerms}
              data-cy='register-accept-terms'
            />
          } label={
            <Typography variant='body2'>
              {t('i_agree_to_the')}{' '}
              <a href={process.env.REACT_APP_CURRENT_TERMS} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                {t('terms_of_service')}
              </a>
              {' '}{t('and_have_read_the')}{' '}
              <a href='https://icecream.club/legal/privacy' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                {t('privacy_policy')}
              </a>.
            </Typography>
          } />
        </FormGroup>

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
          disabled={ !acceptTerms || !(password.length > 5) || !EmailValidator.validate(email)}
          data-cy='register-submit'
        >
          {t('create_account')}
        </LoadingButton>

      </form>

      <Typography variant="body1" sx={{ mt: 4, textAlign: 'center' }}>
        {t('already_a_member')} <Link sx={{ color: "primary.text" }} onClick={() => router('login')}>{t('log_in')}</Link>
      </Typography>
    </>
  );
}
