import { FC, memo, ReactNode } from 'react';

import { Contact } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { ShareItem, ShareItemProps } from './ShareItem';
import { CanonicalItem, CanonicalItemProps } from './CanonicalItem';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';


interface CollectionItemProps {
  share?: ShareItemProps['share'],
  canonicalContent?: CanonicalItemProps['canonicalContent'],
  listCreatedBy?: Pick<Contact, '_id'>
}

export const CollectionItem: FC<CollectionItemProps> = memo(function CollectionItem({ share, canonicalContent, listCreatedBy }: CollectionItemProps) {

  const toggleInWatchlist = useToggleInWatchlist();
  const { toggleWatched } = useToggleWatched();

  const canonicalContentId = canonicalContent?._id || share?.canonicalContent?._id;

  const seen = useIsWatched(share?._id, canonicalContentId);
  const setIsWatched = (v: boolean) => toggleWatched(canonicalContentId, share?._id, v);
  const watchlist = useIsInWatchlist(canonicalContentId, share?._id);
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(canonicalContentId, share?._id, v);

  let item: ReactNode = '';
  if(canonicalContent) {
    item = <CanonicalItem canonicalContent={canonicalContent} share={share} listCreatedBy={listCreatedBy} isWatched={seen} isInWatchlist={watchlist} />;
  } else if(share) {
    item = <ShareItem share={share} listCreatedBy={listCreatedBy} isWatched={seen} isInWatchlist={watchlist} />;
  } else {
    throw new Error('CollectionItem: canonicalContent or share must be provided');
  }

  return (
    <SwipeableUnfurl
      isWatched={seen}
      setIsWatched={setIsWatched}
      isInWatchlist={watchlist}
      setIsInWatchlist={setIsInWatchlist}
      backgroundSx={{ borderRadius: 0 }}
    >
      {item}
    </SwipeableUnfurl>
  );
});
