import { FC, SyntheticEvent, useState } from "react";
import { Box, Divider } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Contact, Message, Rating, Share } from "@/generated/graphql";
import { useSnackbar } from "@/hooks/snackbar";
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from "@/hooks/watch-state";
import { Avatar } from "@/components/Avatar";
import { UserName } from "@/components/UserName";
import { RatingIcon } from "@/components/icons/RatingIcon";
import { FollowButton } from "@/components/FollowButton";
import { ShareText, ShareTextProps } from "@/components/ShareText";
import { SwipeableUnfurl } from "@/components/unfurl/SwipeableUnfurl";
import { CompactShareUnfurl, CompactShareUnfurlProps } from "@/components/unfurl/CompactShareUnfurl";
import { Dialog, DialogProps } from "@/components/unfurl/Dialog";
import { ReshareIconButton } from "@/components/Post/ReshareIconButton";
import { AutoplayingVideo } from "@/components/AutoplayingVideo";
import { ShareContext, ShareContextProps } from "@/components/ShareContext";
import { WatchlistIconButton } from "./WatchlistIconButton";
import { WatchedIconButton } from "./WatchedIconButton";


export interface VideoShareProps {
  share: Pick<Share, '_id'> & {
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
    context?: ShareContextProps['context'] | null,
    message: Pick<Message, 'link'> | null,
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
  } & ShareTextProps['share'] & CompactShareUnfurlProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & DialogProps['canonicalContent'] & CompactShareUnfurlProps['canonicalContent'] | null,
  onClick: (ev: SyntheticEvent) => void,
}


export const VideoShare: FC<VideoShareProps> = function VideoShare({ share, canonicalContent, onClick }) {

  const theme = useTheme();

  const canonicalContentId = canonicalContent?._id || share.canonicalContent?._id;

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  const isWatched = useIsWatched(share._id, canonicalContentId);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (active: boolean) => toggleWatched(canonicalContentId, share._id, active);
  const isInWatchlist = useIsInWatchlist(share._id, canonicalContentId);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (active: boolean) => toggleInWatchlist(canonicalContentId, share._id, active);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          pb: 0,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          maxWidth: '100%'
        }}
        onClick={onClick}
      >

        <Avatar
          sx={{ flex: '0 0 32px' }}
          size={'medium'}
          contact={share.sender?._id}
          link={true}
        />

        <Box sx={{ flex: 1, flexDirection: 'column', maxWidth: 'calc(100% - 40px)' }}>

          {share.sender &&
            <Box sx={{display: "flex", alignItems: "center", gap: 1 }}>
              <UserName
                userId={share.sender?._id}
                addLink={true}
                postfix=' shared'
                sx={{ fontWeight: 500, flex: '0 0 auto' }}
              />
              {share.sendersRating &&
                <RatingIcon value={share.sendersRating.rating} highlight />
              }
              {share.context && <ShareContext context={share.context} />}
              <Box sx={{ flex: '1 1 auto' }}>
                <FollowButton contact={share.sender} lineHeight='20px' divider={false} />
              </Box>
            </Box>
          }
        </Box>
      </Box>

      {share.message?.link &&
        <AutoplayingVideo url={share.message.link} sx={{ backgroundColor: 'grey.100', ml: 7, mt: -0.5, mr: 2, mb: 1 }} onClick={onClick} />
      }

      <Box sx={{ pl: 7, pr: 2, pb: 2, borderBottom: `1px solid`, borderColor: 'grey.100' }} onClick={onClick}>

        <ShareText share={share} link={false} color={theme.palette.background.paper} maxHeight={100} toggle={false} sx={{ mb: 0.5 }} />

        {share.unfurl &&
          <Box sx={{ maxWidth: '280px' }}>

            <SwipeableUnfurl
              isWatched={isWatched}
              setIsWatched={(v: boolean) => {
                setIsWatched(v);
                v && watchedSnackbar();
              }}
              isInWatchlist={isInWatchlist}
              setIsInWatchlist={(v: boolean) => {
                setIsInWatchlist(v);
                v && watchlistSnackbar(() => setDialogOpen(true));
              }}
            >
              <CompactShareUnfurl
                share={{...share, unfurl: share.unfurl }}
                canonicalContent={canonicalContent}
                sx={{ border: 'none', backgroundColor: 'grey.100' }}
                setDialogOpen={setDialogOpen}
              />
            </SwipeableUnfurl>

          </Box>
        }

        {dialogOpen &&
          <Dialog share={share} canonicalContent={canonicalContent} open={dialogOpen} setOpen={setDialogOpen} />
        }

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1.5, mt: 0.5, maxWidth: '280px' }}>
          <WatchlistIconButton share={share} canonicalContent={canonicalContent} />

          <Divider orientation="vertical" flexItem sx={{ my: '8px' }} />

          <WatchedIconButton share={share} canonicalContent={canonicalContent} />

          <Divider orientation="vertical" flexItem sx={{ my: '8px' }} />

          <ReshareIconButton share={share} sx={{ height: '24px' }} />

        </Box>

      </Box>
    </Box>
  );
}
