import { ReactNode } from 'react';
import escapeStringRegexp from 'escape-string-regexp';
import { hashCode } from './string';


export type Segment = string | ReactNode;


export type Injector = Record<string, any> & { // eslint-disable-line @typescript-eslint/no-explicit-any
  callout: string,
};


function split(text: string, callout: string): string[] {
  const re = new RegExp(`(\\W|^)(${escapeStringRegexp(callout)})(\\W|$)`);
  const calloutMatches = re.exec(text);
  if (!calloutMatches) {
    return [text];
  }
  const preGroupLength = calloutMatches[1].length;
  const pre = text.slice(0, calloutMatches.index + preGroupLength);
  const post = text.slice(calloutMatches.index + preGroupLength + callout.length);
  return [
    pre, // text till index
    callout,
    ...split(post, callout) // text efter callout
  ];
}

function inject(text: string, callout: string, callback: (i: string) => ReactNode): Segment[] {

  const calloutSplits = split(text, callout);
  const segments: Segment[] = [];
  calloutSplits.forEach((calloutSplit, i) => {
    if(calloutSplit === callout) {
      const link = callback(i.toString());
      segments.push(link)
    } else {
      segments.push(calloutSplit);
    }
  });
  return segments;
}


function injectIntoSegments(segments: Segment[], callout: string, callback: (i: string) => ReactNode): Segment[] {
  return segments.map(messageSegment => {
    if(typeof messageSegment === 'string') {
      return inject(messageSegment, callout, callback);
    }
    return messageSegment;
  }).flat();
}


export function injectIntoParagraphs(paragraphs: Segment[][], injectors: Injector[], createComponent: (i: Injector, key: string) => ReactNode): Segment[][] {
  let c = 0;
  return paragraphs.map(paragraphSegments => {
    injectors.forEach(injector => {
      paragraphSegments = injectIntoSegments(paragraphSegments, injector.callout, i => createComponent(injector, `${hashCode(injector.callout)}_${i}_${c++}`));
    });
    return paragraphSegments;
  });
}
