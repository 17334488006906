import { FC, ReactNode, SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Contact, Message, Share } from '@/generated/graphql';
import { testIccCollection } from '@/utils/url';
import { canonicalLink } from '@/utils/routes';
import { User } from '@/components/User';
import { ListShare, ListShareProps } from '@/components/Post/ListShare';
import { DecoratedText, DecoratedTextProps } from '@/components/DecoratedText';
import { ShareContext, ShareContextProps } from '@/components/ShareContext';
import { SmallItem, SmallItemProps } from './SmallItem';


export type InlineShareProps = {
  share: Pick<Share, '_id' | 'commentCount'> & {
    list?: ListShareProps['list'] | null,
    sender?: Pick<Contact, '_id'> | null,
    message?: Pick<Message, 'link' | 'text' | 'language'> | null,
    context?: ShareContextProps['context'] | null,
    mentions?: DecoratedTextProps['mentions'] | null,
    textLinks?: DecoratedTextProps['links'] | null,
  } & SmallItemProps['share'],
  canonicalContent?: Pick<CanonicalContent, '_id'> & SmallItemProps['canonicalContent'] | null,
  actions?: ReactNode,
  showUnfurl?: boolean,
  handleClick: (e: SyntheticEvent) => void,
};


export const InlineShare: FC<InlineShareProps> = function InlineShare({ share, canonicalContent, actions, showUnfurl=true, handleClick }) {

  const theme = useTheme();

  return (
    <Box
      onClick={handleClick}
      sx={{
        pt: share.sender || share.message?.text ? 0.75 : 1,
        px: 1,
        pb: actions ? 0.25 : 1,
        borderRadius: 0.5,
        border: `1px solid ${theme.palette.grey['200']}`,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        gap: 0.5
      }}
    >
      {share.sender &&
        <Box sx={{display: "flex", alignItems: "center", gap: 1 }}>
          <User
            userId={share.sender._id}
            addLink={false}
            size={'small'}
            postfix=' shared'
            sx={{ fontWeight: 500 }}
          />
          {share.context && <ShareContext context={share.context} />}
        </Box>
      }

      {share.message?.text &&
        <Box
          sx={{
            p: 0,
            my: 0,
            maxHeight: '72px',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            lineClamp: 1,
            textOverflow: 'ellipsis',
            overflowWrap: 'break-word'
          }}
          lang={share.message?.language}
        >
          <DecoratedText text={share.message?.text} links={share.textLinks || undefined} mentions={share.mentions || undefined} splitParagraphs={false} />
        </Box>
      }

      {showUnfurl &&
        <Box sx={{ maxWidth: '280px' }}>
          {(share.message?.link && share.sender && testIccCollection(share.message.link) && share.list?.createdBy) ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <ListShare list={share.list} sx={{ border: 'none', backgroundColor: 'grey.100' }} link={canonicalLink(canonicalContent?._id, share._id)} showUser={share.list.createdBy._id !== share.sender._id} />
          ) : (
            <SmallItem share={share} canonicalContent={canonicalContent} />
          )}
        </Box>
      }

      {actions}

    </Box>
  );
}
