import { DOMAttributes, RefObject, useEffect, useState } from 'react';


type CustomEvents<K extends string> = { [key in K] : (event: CustomEvent) => void };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomElement<T, K extends string> = Partial<T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>>;

export type SwiperContainer = CustomElement<HTMLElement & {
  ref: RefObject<SwiperContainer>,
  'space-between': number,
  'centered-slides': boolean,
  autoplay: string,
  pagination: string,
  init: string,
  injectStyles: string[],
  initialize: () => void
}, 'update'>;


export type SwiperSlide = CustomElement<HTMLElement & {
  key: string | number,
}, 'update'>;


declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['swiper-container']: SwiperContainer;
      ['swiper-slide']: SwiperSlide;
    }
  }
}


export function initialize(): Promise<void> {
  return import('swiper/element/bundle')
    .then((el) => {
      console.log('register swiper', el);
      el.register();
    });
}


export function useSwiper() {
  const [swiperLoaded, setSwiperLoaded] = useState(false);
  useEffect(() => {
    initialize().then(() => setSwiperLoaded(true));
  }, []);
  return swiperLoaded;
}


export {};
