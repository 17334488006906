import { FC, SyntheticEvent } from "react";
import { Box, SxProps } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Share } from "@/generated/graphql";
import { useAnalyticsQueued } from "@/hooks/delicious-analytics";
import { useIsInWatchlist, useToggleInWatchlist } from "@/hooks/watch-state";
import { AddCircle, AddCircleFilled } from "@/components/icons";


export type WatchlistIconButtonProps = {
  share?: Pick<Share, '_id'> | null,
  canonicalContent?: Pick<CanonicalContent, '_id'> | null
  sx?: SxProps,
}


export const WatchlistIconButton: FC<WatchlistIconButtonProps> = function WatchlistIconButton({ share, canonicalContent, sx={} }) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();

  const isInWatchlist = useIsInWatchlist(share?._id, canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(canonicalContent?._id, share?._id, v);

  const handleClickWatchlist = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    track('toggle_watchlist_button', { category: 'explore' });
    setIsInWatchlist(!isInWatchlist);
  };

  if(!share?._id && !canonicalContent?._id) {
    console.error('WatchlistIconButton: share or canonicalContent must be defined');
    return null;
  }

  return (
    <Box
      sx={{
        typography: 'body2',
        color: "text.secondary",
        display: 'flex',
        alignItems: 'center',
        height: '24px',
        ...sx
      }}
      onClick={handleClickWatchlist}
    >
      {isInWatchlist ?
        (<AddCircleFilled sx={{ marginRight: '4px', width: 14, height: 14, color: 'primary.main' }} />) :
        (<AddCircle sx={{ marginRight: '4px', width: 14, height: 14, color: theme.palette.grey[500], strokeWidth: 2 }} />)
      }
      Watchlist
    </Box>
  );
}
