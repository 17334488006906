import { gql } from '@apollo/client';
import { CanonicalFragment } from './canonicalFragment';
import { ShareCommentsFragment, ShareFragment } from './shareFragment';

export const SEARCH = gql`
  query Search($input: SearchInput!) {
    search(input: $input) {
      hits {
        highlight {
          key
          html
        }
        score
        type
        canonicalContent {
          ...CanonicalFragment
        }
        share {
          ...ShareFragment
        }
      }
    }
  }
  ${CanonicalFragment}
  ${ShareFragment}
`;


export const SEARCH_CANONICALS = gql`
  query SearchCanonicals($input: SearchInput!) {
    searchCanonicals(input: $input) {
      hits {
        highlight {
          key
          html
        }
        score
        type
        canonicalContent {
          ...CanonicalFragment
        }
      }
    }
  }
  ${CanonicalFragment}
`;

export const SEARCH_SHARES = gql`
  query SearchShares($input: SearchInput!) {
    searchShares(input: $input) {
      hits {
        highlight {
          key
          html
        }
        score
        type
        share {
          ...ShareFragment
          ...ShareCommentsFragment
        }
      }
    }
  }
  ${ShareFragment}
  ${ShareCommentsFragment}
`;


