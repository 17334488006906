
export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
export const WEEK_IN_SECONDS = 7 * DAY_IN_SECONDS;
export const MONTH_IN_SECONDS = 30 * DAY_IN_SECONDS;
export const YEAR_IN_SECONDS = 365 * DAY_IN_SECONDS;

export type TimeUnitOption = {
  to: number,
  short: { singular: string, plural: string },
  long: { singular: string, plural: string },
  length: number,
}

export type OptionsType = TimeUnitOption[]

export const DEFAULT_OPTIONS = [
  { to: 10*MINUTE_IN_SECONDS, short: { singular: 'now', plural: 'now' }, long: { singular: 'now', plural: 'now' }, length: 0 },
  { to: DAY_IN_SECONDS, short: { singular: 'h', plural: 'h' }, long: { singular: ' hour ago', plural: ' hours ago' }, length: HOUR_IN_SECONDS },
  { to: WEEK_IN_SECONDS, short: { singular: 'd', plural: 'd' }, long: { singular: ' day ago', plural: ' days ago' }, length: DAY_IN_SECONDS },
  { to: MONTH_IN_SECONDS, short: { singular: 'wk', plural: 'wk' }, long: { singular: ' week ago', plural: ' weeks ago' }, length: WEEK_IN_SECONDS },
  { to: YEAR_IN_SECONDS, short: { singular: 'mo', plural: 'mo' }, long: { singular: ' month ago', plural: ' months ago' }, length: MONTH_IN_SECONDS },
  { to: Infinity, short: { singular: 'yr', plural: 'yr' }, long: { singular: ' year ago', plural: ' years ago' }, length: YEAR_IN_SECONDS },
];


export function formatAge(date: Date, format: 'short' | 'long' = 'short', options: OptionsType = DEFAULT_OPTIONS): string {
  const now = new Date();
  const diff = Math.floor((now.getTime() - date.getTime()) / 1000);

  const sortedTypes = options.sort((a, b) => a.to - b.to);

  for(const type of sortedTypes) {
    if(diff < type.to) {
      if(type.length === 0) {
        return type.short.singular;
      }
      const elapsed = Math.floor(diff / type.length);
      if(elapsed !== 1) {
        return elapsed + type[format].plural;
      }
      return elapsed + type[format].singular;
    }
  }

  return '';
}
