import { FC, useState } from 'react';

import { CanonicalContent, Movie, Rating, Share, TvShow, Unfurl } from '@/generated/graphql';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '@/hooks/watch-state';
import { useSnackbar } from '@/hooks/snackbar';
import { Image } from '@/components/Image';
import { TinyUnfurl } from '@/components/unfurl/TinyUnfurl';
import { Dialog, DialogProps } from '@/components/unfurl/Dialog';
import { SwipeableUnfurl } from '@/components/unfurl/SwipeableUnfurl';


export type TinyItemProps = {
  share?: Pick<Share, '_id'> & {
    message?: Pick<Share['message'], 'link'> | null,
    rating?: Pick<Rating, 'rating'> | null,
    unfurl: Pick<Unfurl, 'title' | 'link' | 'images'>,
  } | null,
  canonicalContent?: Pick<CanonicalContent, '_id' | 'type'> & {
    rating?: Pick<Rating, 'rating'> | null,
    movie?: Pick<Movie, 'title' | 'poster'> | null,
    tvshow?: Pick<TvShow, 'title' | 'poster'> | null,
  } & DialogProps['canonicalContent'] | null,
}


export const TinyItem: FC<TinyItemProps> = function TinyItem({ share, canonicalContent }) {

  let content = null;
  if(canonicalContent && canonicalContent?.type) {
    content = canonicalContent.type === 'movie' ? canonicalContent.movie : canonicalContent.tvshow;
  }

  const image = share?.unfurl?.images?.[0] || content?.poster;

  const isWatched = useIsWatched(share?._id, canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (active: boolean) => toggleWatched(canonicalContent?._id, share?._id, active);
  const isInWatchlist = useIsInWatchlist(share?._id, canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (active: boolean) => toggleInWatchlist(canonicalContent?._id, share?._id, active);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const { watchedSnackbar, watchlistSnackbar } = useSnackbar(share, canonicalContent);

  return (
    <>
      <SwipeableUnfurl
        isWatched={isWatched}
        setIsWatched={(v: boolean) => {
          setIsWatched(v);
          v && watchedSnackbar();
        }}
        isInWatchlist={isInWatchlist}
        setIsInWatchlist={(v: boolean) => {
          setIsInWatchlist(v);
          v && watchlistSnackbar(() => setDialogOpen(true));
        }}
        showLabel={false}
      >
        <TinyUnfurl
          title={content?.title || share?.unfurl?.title}
          link={share?.unfurl?.link}
          image={image && <Image constraints='72x72_c' {...image} width={72} height={72} />}
          isWatched={isWatched}
          isInWatchlist={isInWatchlist}
          setDialogOpen={setDialogOpen}
          rating={share?.rating || canonicalContent?.rating}
        />
      </SwipeableUnfurl>

      {dialogOpen &&
        <Dialog share={share} canonicalContent={canonicalContent} open={dialogOpen} setOpen={setDialogOpen} />
      }
    </>
  );
}
