import { useEffect, useState, useCallback, FC, SyntheticEvent } from 'react';
import { Divider, Typography, Link } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as EmailValidator from 'email-validator';
import { verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword, getAdditionalUserInfo } from 'firebase/auth';

import { useTranslation } from '../../utils/i18n';
import { firebaseAuth } from '../../firebase';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSnackbar } from '@/hooks/snackbar';
import { EmailInput, PasswordInput, MagicForm } from '../../components/auth';
import { Router } from './Container';


export type ResetProps = {
  oobCode: string,
  email: string,
  setEmail: (email: string) => void,
  router: Router,
};


//
// This is currently not in use, password reset is handled by firebase!
//
export const Reset: FC<ResetProps> = function Reset({ oobCode, email, setEmail, router }) {

  const { track } = useAnalyticsQueued();
  const { t } = useTranslation('auth');

  const [ loading, setLoading ] = useState<boolean>();
  const { show } = useSnackbar();
  const [ password, setPassword ] = useState('');
  const [ verifiedCode, setVerifiedCode ] = useState('');

  const reset = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('password_reset_submit', { category: 'auth' });
    setLoading(true);
    confirmPasswordReset(firebaseAuth, verifiedCode, password)
      .then(() => {
        show({ text: 'Password updated, signing in...', severity: 'info' });
        track('password_reset_success', { category: 'auth', action: 'reset' });

        // Password reset has been confirmed and new password updated.
        setTimeout(() => {
          signInWithEmailAndPassword(firebaseAuth, email, password)
            .then((result) => {
              // We should have successful auth
              const userInfo = getAdditionalUserInfo(result);
              if (userInfo?.isNewUser) {
                track('signup', { category: 'auth', action: 'reset', provider: 'email-and-password' });
              } else {
                track('login', { category: 'auth', action: 'reset', provider: 'email-and-password' });
              }
            })
            .catch((e) => {
              console.error(e);
              track('login_auth_error', { category: 'auth', error: e.code, action: 'reset', provider: 'email-and-password' });
              show({ text: e.message, severity: 'error' });
              setLoading(false);
            });
        }, 1000);

      }).catch((err) => {
        console.error(err);
        track('password_reset_error', { category: 'auth', error: err.code });
        show({ text: err.message, severity: 'error' });
        setLoading(false);
      });
  }, [ email, show, password, verifiedCode, track ]);

  useEffect(() => {
    if (oobCode) {
      track('password_reset_verify_code', { category: 'auth' });
      setLoading(true);
      verifyPasswordResetCode(firebaseAuth, oobCode)
        .then((email) => {
          track('password_reset_verified', { category: 'auth' });
          setEmail(email);
          setVerifiedCode(oobCode);
          setLoading(false);
        }).catch((err) => {
          track('password_reset_verify_error', { category: 'auth', error: err.code });
          if(err.code === 'auth/invalid-action-code') {
            show({ text: 'Invalid code, please go back and request a new reset mail', severity: 'error' });
          } else {
            console.error(err);
            show({ text: err.message, severity: 'error' });
          }
          setLoading(false);
        });
    } else {
      track('password_reset_missing_code', { category: 'auth' });
      show({ text: 'Missing code, please request a new reset mail', severity: 'error' });
    }
  }, [ oobCode, setEmail, show, track ]);

  return (
    <>
      <EmailInput email={email} autoFocus={false} disabled setEmail={() => null} />

      <form onSubmit={reset}>

        <PasswordInput password={password} setPassword={setPassword} placeholder={t('choose_password')} />

        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          loading={loading}
          disabled={ (!password || !EmailValidator.validate(email)) && !!verifiedCode}
          data-cy='login-submit'
        >
          Reset password
        </LoadingButton>

      </form>

      <Divider sx={{ mt: 2, mb: 2 }}>or</Divider>

      <MagicForm email={email} variant="outlined" EmailInputProps={{ type: 'hidden', setEmail: () => null }} />

      <Typography variant="body1" sx={{ mt: 1 }}>
        We{"'"}ll send you a magic sign-in link so that you can sign in without a password.
      </Typography>

      <Typography variant="body1" sx={{ mt: 4, textAlign: 'center' }}>
        Remembered your password? <Link onClick={() => router('login')}>Login</Link>
      </Typography>
    </>
  );
}
