import { Container } from './Container';

export * from './Forgot';
export * from './Login';
export * from './Logout';
export * from './Magic';
export * from './Register';
export * from './Welcome';
export * from './Reset';

export default Container;
